<template lang="pug">
  .builder-view.hide-scroll-page
    .st-container.row.pt-3
      right-panel-layout
        table-page
        template(slot="panel")
          modal-node-detail(
            @select-hooked="selectHookedHandler"
            :node="selected")
    change-builder-look(v-if="this.$route.path.includes('builder')")
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import RightPanelLayout from '@/views/administration/common/RightPanelLayout'

import TablePage from './pages/Table'
import ModalNodeDetail from '@/components/modals/builder/ModalNodeDetail'
import ChangeBuilderLook from '@/components/controls/ChangeBuilderLook'

export default {
  name: 'Builder',

  components: {
    RightPanelLayout,
    TablePage,
    ModalNodeDetail,
    ChangeBuilderLook
  },

  methods: {
    ...mapActions('skills', [
      'selectItem'
    ]),

    selectHookedHandler (payload) {
      this.selectItem(payload)
    }
  },

  computed: {
    ...mapGetters('skills', [
      'selected'
    ])

  },
  watch: {
    toggledBuilderLook (value) {
      if (value && value.id === 'TREE') {
            this.$router.push({ name: 'BuilderOld' })
      }
    }
  }
}

</script>

<style lang="scss" scoped>
.hide-scroll-page {
  height: calc(100vh - 80px);
  overflow: hidden;
}

.builder-view {
  display: flex;
  align-items: center;
  td {
    vertical-align: middle !important;
  }

  .v-card {
    width: 100%;
  }
}

.st-container {
  position: relative;

  .__rail-is-vertical:before {
    right: 0;
  }

  .__rail-is-horizontal {
    bottom: -6px !important;
  }

  .hasHBar {
    .__panel {
      margin-bottom: -25px;
    }
  }
}
</style>
